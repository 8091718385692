/**
 * 接口地址
 */

// #ifdef APP-PLUS || MP-WEIXIN
export const API_URL = 'https://moban.yxjky.com'
// #endif

// #ifdef H5
const ENV_BASE_URL = {
    development: '/api', //开发环境
    // development: '',
    production: 'https://moban.yxjky.com' //生产环境
}
const ENV_API_URL = {
    development: `${ENV_BASE_URL.development}`, //开发环境
    production: `${ENV_BASE_URL.production}` //生产环境
}
export const API_URL = ENV_API_URL[process.env.NODE_ENV || 'development'] //后台接口域名
// #endif
export const imgUrl = 'https://moban.yxjky.com/'
export const audioUrl = 'https://qaapi.qayxjk.com/'
export const THEME_PRIMARY = '#0075D0'
export const REQUEST_SUCCESS_CODE = 200